$(document).ready(function() {
  var mainSwiper = new Swiper('#main-swiper', {
    speed: 1000,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    simulateTouch: false,
    autoplay: {
      delay: 4000
    }
  });
  mainSwiper.on('slideChange', function () {
    $('.big-logo').removeClass('show');
    setTimeout(function(){
      $('.big-logo').addClass('show');
    }, 700);
  });
  var gallerySwiper = new Swiper('#gallery-swiper', {
    speed: 1000,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
  var $lg = $('#lightgallery');
  $lg.lightGallery({
    share: false,
    progressBar: false,
    actualSize: false
  });

  $('#open-gallery').on('click', function(e){
    e.preventDefault();
    $('#lightgallery a').first().trigger('click');
  });

  $('#gallery-swiper').on('click', '.swiper-slide', function(){
    $('#lightgallery a').eq($(this).index()).trigger('click');
  });

  $('.open-pdf').on('click', function (e) {
      e.preventDefault();
      var file = $(this).data('file');
      var id = $(this).data('id');

      var shareButton = $('.pdf-popup a[data-url]');
      shareButton.attr('href', shareButton.data('url') + '?flatId=' + id);

      $('.pdf-popup a[data-print]').data('print', file);
      $('.pdf-popup a[download]').attr('href', file);
      $('.pdf-popup iframe').attr('src', '/pdfjs/web/viewer.html?file=' + file);
      $('.pdf-popup').addClass('show');
  });

  $('.close-popup').on('click', function (e) {
      e.preventDefault();
      $('.pdf-popup').removeClass('show');
  });

  $('[data-print]').click(function (e) {
      e.preventDefault();
      var file = $(this).data('print');
      printJS(file);
  });

  $('.close-menu, .menu-backdrop, nav .menu li a').on('click', function(){
    closeMenu();
  });

  $('.hamburger').on('click', function(){
    openMenu();
  });

  //scroll na kotvu
  $('.scroll-to').on('click', function(e){
    var link = $(this).attr('href');
    $("html, body").stop().animate({ scrollTop: $(link).offset().top - $('header').outerHeight() }, 1000, "easeInOutSine" );
    e.preventDefault();
  });

  new WOW().init();

  $('.message .close-message').on('click', function(){
    $(this).parent('.message').fadeOut(500);
  });

  function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
  }

  function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  /** Event popup Start **/
  //if (getCookie('modalEventPopUp') !== '1') {
    $('#popUpOnLoad').modal('show');
  //}
  $('#popUpOnLoadBtn').on('click', function(){
    $('#popUpOnLoad').modal('hide');
  });

  $('.js-close-event-pop-up').on('click', function(){
    //setCookie('modalEventPopUp', 1, 365);
  });
  /** Event popup End **/
});

$(window).on('load', function(){
  $('.big-logo').addClass('show');
});
function openMenu() {
  $('header').addClass('menu-opened');
  $('body').addClass('menu-opened');
}
function closeMenu() {
  $('header').removeClass('menu-opened');
  $('body').removeClass('menu-opened');
}

var lastScrollTop = 0;
var windowWidth = $(window).width();
$(window).scroll(function(event){
  if (windowWidth < 1200) {
    var st = $(this).scrollTop();
    if (st > lastScrollTop && st > 60){
      $('header').addClass('header-hidden');
    } else {
      $('header').removeClass('header-hidden');
    }
    lastScrollTop = st;
  }
});
